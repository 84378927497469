.map-container {
  margin-bottom: 30px;
}

.highlight {
  font-size: 1.25rem;
  font-weight: bold;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

/* .places-container {

} */

/* .z-index-max: {
  z-index: 9999999999;
} */
